.table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
  font-size: 17px;

}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}


.national-news {
  background-image: url(../images/news-bg.jpg);
  position: relative;

  &.inner-page {
    background-image: none;
    background: $color_white;

    .overlay {
      opacity: 0;
    }

    .section-title {
      color: $primary_color;
    }

  }
  .box {
    margin-bottom: 30px;
  }
  .section-title {
    color: $color_white;
  }
  .overlay {
    position: absolute;
    background: #06305d;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.8;
  }
  h3 {
    line-height: 26px;
    position: relative;
    font-size: 20px;
    margin-bottom: 10px;
    a {
      color: $primary_color;
      cursor: pointer;
      text-transform: inherit;
      font-weight: 600;
    }

  }
  .blog-inner {
    position: relative;
    top: -35px;
    z-index: 33;
    background: #fff;
    padding: 15px;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: -32px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .box:hover {
    .blog-inner {
      top: -40px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    }

    h3 a {
      color: $secondary_color;
    }
  }
  .blog-bottom {
    position: relative;
    padding: 0 10px;
  }
  p {
    margin-bottom: 0;
  }

}

/*------------------------ pagination -------------------------*/
ul.pagination{
  margin: 0;
}
.page-item:first-child .page-link, .page-item:last-child .page-link {
  border-radius: 0;
}

.page-item .page-link {
  color: #314455;
  transition: all ease 0.3s;
}

.page-item .page-link:hover {
  color: #FFF;
  background: $secondary_color;
}

/*--------------------- gallery --------------------*/

/*--------------------- gallery --------------------*/

.gallery {
  margin-top: 30px;
  .section-title {
    margin-bottom: 30px;
  }
  .gallery-inner {
    margin-bottom: 30px;
  }
  .box {
    position: relative;
    img {
      width: 100%;
    }
    .centerd {
      display: table;
      vertical-align: middle;
      width: 100%;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      top: 0;
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.5); /* Black see-through */
      width: 100%;
      transition: .5s ease;
      opacity: 0;
      color: white;
      font-size: 20px;
      padding: 20px;
      text-align: center;
      line-height: 10;
    }
  }

  .album-title h4 {
    font-size: 16px;
    background: #222222;
    color: #bcbcbc;
    padding: 10px 15px;
    font-weight: 600;
    text-align: center;
  }

  .gallery-inner:hover .overlay {
    opacity: 1;
  }
  .youtube i {
    color: red;
    font-size: 50px;
    line-height: 4.5;
  }

}

.map {
  padding: 30px 0;
}

.download-link {
  margin-bottom: 15px;
  h3 {
    font-size: 20px;
    color: rgba(20, 23, 124, 0.66);
  }
  a {
    color: $primary_color;
    font-size: 14px;
    font-weight: 700;

    &:hover {
      color: $secondary_color;
    }
  }
}

.inner_page {
  figure {
    margin-bottom: 15px;
  }

  .publication-outer {
    box-shadow: -2px 1px 13px -6px rgba(0, 0, 0, 0.75);
    border: 1px solid #f7f7f7;
    padding: 20px;
    h3 {
      padding-top: 15px;
      margin-bottom: 0;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      a{
        color: #222222;
      }
    }
    figure {
      margin-bottom: 0;
    }
    .row {
      border-bottom: 1px solid #dedede;
      padding: 20px 0;
      &:last-child {
        border: none;
        margin-bottom: 0;
      }
    }
  }
}

/*--------------------------- Contact Us ---------------------------------*/
.contact_form_page {
  margin-bottom: 50px;
  margin-top: 30px;
}

.contact-icon {

  margin-bottom: 30px;
}

.contact-icon .box {
  display: flex;
  align-items: center;
}

.contact-icon .icon-box {
  width: 15%;
}

.contact-icon .text p {
  margin: 0;
}

.contact-icon .contact-inner a {
  font-size: 16px;
  display: block;
  color: #737373;
}

.contact-icon .contact-inner i {
  color: #fff;
  display: inline-block;
  font-size: 26px;
  text-align: center;
  line-height: 48px;
  margin-right: 15px;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  border: 1px solid $secondary_color;
  background: $secondary_color;
  margin-top: 15px;
}

.contact-icon .contact-inner a span {
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
}

.contact_form .wpcf7 p {
  margin-bottom: 0;
}

.contact_form .wpcf7 label {
  display: inline-block;
  margin-bottom: .5rem;
  width: 100%;
}

.contact_form .wpcf7 input, .contact_form .wpcf7 .wpcf7-textarea {
  background: #f2f2f2;
}

.contact_form .wpcf7 .wpcf7-textarea {
  height: 200px;
}

textarea {
  width: 100%;
}

.contact_form .wpcf7 input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 12px 28px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  letter-spacing: 0.5px;
  background: $secondary_color;
  color: $color_white;
  border: 1px solid $secondary_color;
  transition: all 0.3s ease;

  &:hover {
    background: $secondary_color;
    color: $color_white;
    border: 1px solid $secondary_color;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
}

/*------------------------------------------------------------*/
figure.message {
  max-width: 250px;
}

.inner-page figure {
  margin-bottom: 20px;
}

ul.sidebar{
  border: 2px solid #e4e4e4;
  margin: 0;
  padding: 0;
  background: #FFF;
  border-radius: 2px;
  li{
    list-style-type: none;
    border-bottom: 1px solid #e4e4e4;
    padding: 7px 8px;
    &:last-child{
      border: none;
    }
    &:hover{
      background: $secondary_color;
      a{
        color: #FFF;
      }
    }
    a{
      color: #222222;
      font-size: 15px;
      font-weight: 500;
      padding: 10px 5px;
      display: block;
    }
  }

}


/*********************
*    team
*
***********************/
.business_bliss_team {
  width: 100%;
  background: #f9f8f8;
  display: block;
}

.business_bliss_team .box {
  text-align: center;
  margin-bottom: 15px;
  background: #FFF;
  padding-bottom: 15px;
}

.business_bliss_team .box h4 {
  margin-bottom: 0;
}

.business_bliss_team .box h4 a {
  color: #404040;
  font-weight: 500;
  font-size: 18px;
}

.business_bliss_team .box span {
  text-align: center;
  display: block;
  color: #0d8fc3;
  font-size: 14px;
}

.business_bliss_team .box figure {
  display: block;
  position: relative;
  overflow: hidden;
}

.business_bliss_team .box figure a.link-icon {
  position: absolute;
  top: 60%;
  z-index: 999;
  width: 100%;
  text-align: center;
  left: 0;
  visibility: hidden;
  font-size: 25px;

  transition: all 0.3s ease 0s;
}

.business_bliss_team .box figure img {
  transition: all 0.4s ease; /* Animation */
}

.business_bliss_team .box figure:hover img {

  transform: scale(1.1); /* CSS3 */
}

.business_bliss_team .box figure:hover a.link-icon {
  top: 45%;
  visibility: visible;
}

.business_bliss_team .box figure:hover .overlay:before {
  bottom: 0;
  opacity: 0.9;
}
/*-----------------------------*/
.team-box {
  margin: 30px auto 0 auto;
  max-width: 240px;
  background: $color_white;
  padding: 20px;
  box-shadow: 0px 0px 25px 0px rgba(189, 189, 189, 1);
  figure {
    margin: 0 auto;
    max-width: 117px;
    overflow: hidden;
    img {
      margin-bottom: 10px;
    }
  }
  p {
    margin-bottom: 0;

  }
}