/*********************
*    Slider
***********************/
.business_bliss_slider {
  position: relative;
  padding: 0;
  z-index: 0;
  margin-bottom: -10px;
}

.business_bliss_slider .slider-caption {
  position: absolute;
  top: 35%;
  right: 0;
  left: 0;
  text-shadow: none;
  padding: 0 0 0 5%;
}

.business_bliss_slider .thin_layer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0;
  text-shadow: none;
  background: rgba(0, 0, 0, 0.6);
}

.business_bliss_slider .slider-caption h2 {
  color: #fff;
  margin: 0;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 46px;

  @media (max-width: 768px) {
    font-size: 26px;
  }
}

.business_bliss_slider .slider-caption p {
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #fff;
  max-width: 650px;

  @media (max-width: 540px) {
    display: none;
  }
}

.business_bliss_slider .owl-carousel .owl-nav button.owl-next, .business_bliss_slider .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 45%;
  opacity: 0;
  transition: all 0.5s ease;
}

.business_bliss_slider .owl-carousel .owl-nav button.owl-next {
  right: 100px;
}

.business_bliss_slider .owl-carousel .owl-nav button.owl-prev {
  left: 100px;
}

.business_bliss_slider .owl-carousel .owl-nav i {
  color: #999;
  font-size: 83px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent;
  color: inherit;
  text-decoration: none;
}

.business_bliss_slider .owl-carousel:hover .owl-nav button.owl-next {
  right: 0;
  transition: 0.5s;
  opacity: 1;
  outline: 0;
}

.business_bliss_slider .owl-carousel:hover .owl-nav button.owl-prev {
  left: 0;
  opacity: 1;
  outline: 0;
}

.business_bliss_slider .owl-theme .owl-dots {
  position: absolute;
  bottom: 0;
  right: 50px;
}

.message-from-president {
  border: 1px solid #dfdfdf;
  z-index: 999;
  background: #FFF;
  display: flex;
  padding: 3px;
  .content {
    padding: 10px;
    width: 60%;
  }
  h3 {
    font-weight: 700;
    a {
      color: #000;
    }
  }
  p {

    font-size: 14px;
    margin-bottom: 0;
  }
  figure {
    overflow: hidden;
    width: 40%;
    img {
      width: 100%;
    }
  }
}

/*----------------------------------*/

/*--------------------------------------------------------------
##  About section
--------------------------------------------------------------*/
.horti_about_front {
  background: #023c6f;
  .row {
    align-items: center;
  }
  h2 {
    font-family: $font_title;
    font-weight: 700;
    line-height: 1.3em;
    color: #ce4216;
    letter-spacing: -1.6px;
  }

  p {
    color: #e8e8e8;
    font-size:16px;
  }

  figure {
    border-radius: 5px;
    overflow: hidden;
  }

  a {
    color: #ffffff !important;
  }
}

/*------------------------------------------------*/

/*********************
*    services
*
***********************/
.business_bliss_services {
  width: 100%;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background: #FFF;
}

.business_bliss_services .section-title {
  color: #222222;
}

.business_bliss_services .services-list .box {
  position: relative;
  background: #ffffff;
  padding: 30px 31px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  box-shadow: 0px 0px 17px -13px rgba(0, 0, 0, 0.75);
}

.business_bliss_services .services-list .box a h3 {
  position: relative;
  font-size: 26px;
  color: #222222;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: all 0.4s ease;
  text-align: center;
}

.business_bliss_services .services-list .box p {
  text-align: center;
}

.business_bliss_services .services-list .box a:hover h3 {
  color: #982805;
}

.business_bliss_services .services-list .box a .icon-box {
  width: 98px;
  text-align: left;
  line-height: 68px;
  margin: 0 auto;
}

.business_bliss_services .services-list .box a .icon-box i {
  font-size: 48px;
  color: #212529;
  transition: all 0.4s ease;
}

.business_bliss_services .services-list .box a:hover .icon-box i {
  color: #982805;
}

.business_bliss_services .services-list .box h3:before {
  position: absolute;
  bottom: 0;
  left: 39%;
  width: 60px;
  height: 1px;
  content: "";
  background: #982805;
  transition: all 0.4s ease;
}

.business_bliss_services .services-list .box a:hover h3:before {
  background: #982805;
}

/*------------------------------------------------*/
.horti_activities {
  background: #f7f7f7;

  .item {
    box-shadow: 2px 3px 19px -10px rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 30px;
    background: #ffffff;

    h3 {
      padding-top: 15px;
      margin-bottom: 0;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.horti_message {
  background: #023c6f;

  .section-title {
    color: #FFF;
  }
  p {
    color: #e8e8e8;
    text-align: center;
  }
}

.horti_news {
  background: #f7f7f7;
  .section-title {
    text-align: left;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 10px;
      padding: 17px 20px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      list-style-type: none;
      a {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3em;
        color: #333333;
      }
    }
  }
  .update_outer {
    background: #ffffff;
    margin-left: 0;
    margin-right: 15px;
    padding: 40px 30px;
    box-shadow: 2px 3px 19px -10px rgba(0, 0, 0, 0.75);
  }
}

.major-achievement {
  .fact-box {
    width: 13%;
    float: left;
    position: relative;
    overflow: hidden;
    margin: 0 15px 15px;
    border: 1px solid #318fce;
    padding: 15px;
    @media (max-width: 1024px) {
      width: 25%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    .fact-title {
      text-align: center;

      i {
        color: #0474dc;
        font-size: 50px;
        padding-bottom: 20px;
      }

    }
    .lead {
      font-size: 13px;
      color: #0474dc;
      font-weight: 700;
      margin-bottom: 0;
    }
    .fact-info {
      background: rgba(255, 255, 255, .94);
      position: absolute;
      width: 100%;
      left: 0;
      font-size: 15px;
      height: 100%;
      text-align: center;
      top: 100%;
      transition: all .3s ease-in-out;
      border-bottom: 4px solid #0474dc;
      padding: 11px 10px;
    }
    &:hover .fact-info {
      top: 0;
    }
  }
}

/******************
*   Blog
********************/
.nds_news_event {
  border-top: 2px solid #0070b1;
  .blog-bottom {
    position: relative;
    padding: 0 10px;
  }
  .box {
    margin-bottom: 30px;
    &:hover .blog-inner {
      top: -40px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    }
  }
  .blog-inner {
    position: relative;
    top: -35px;
    z-index: 33;
    background: #f6f6f6;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: -32px;
    transition: all 0.3s ease;
    h3 {
      line-height: 26px;
      position: relative;
      font-size: 18px;
      margin-bottom: 10px;
      a {
        color: #333;
        cursor: pointer;
        text-transform: inherit;
        font-weight: 600;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .meta {
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
    margin-top: 10px;
  }
  span {
    display: inline-block;
    margin-right: 10px;
    a {
      color: #666;
    }
    i {
      margin-right: 5px;
      color: #10b64e;
    }
  }
}

.nds_services {
  background: #FFF;
  border-top: 2px solid #0070b1;
  .box {

    z-index: 2;
    background: #f6f6f6;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 10px;
    border: 2px solid #efefef;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background-image: linear-gradient(to right, #f61b10, #ef0963);
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }

    .content {
      padding: 13px 20px;
      .title {
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 18px;
        color: #1f1f25;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      p {
        color: rgba(29, 29, 36, 0.75);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        margin: 0;
      }
    }
  }
}

.item-video {
  position: relative;
  overflow: hidden;
}

.video-overlay {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.center .video-overlay {
  display: none;
}

.video-section {
  margin-bottom: -16px;
}

.yt-video {

  .owl-nav button.owl-next, .owl-nav button.owl-prev {
    position: absolute;
    opacity: 0;
    top: 40%;
    transition: all 0.5s ease;
  }

  .owl-nav button.owl-next {
    right: 100px;
  }

  .owl-nav button.owl-prev {
    left: 100px;
  }

  .owl-nav i {
    color: #999;
    font-size: 83px;
  }

  .owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent;
    color: inherit;
    text-decoration: none;
  }

  &:hover .owl-nav button.owl-next {
    right: 0;
    transition: 0.5s;
    opacity: 1;
    outline: 0;
  }

  &:hover .owl-nav button.owl-prev {
    left: 0;
    opacity: 1;
    outline: 0;
  }

  .owl-dots {
    position: absolute;
    bottom: 0;
    right: 50px;
  }
}

ul.highlight-list {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    border-bottom: 1px solid #cecece;
    font-size: 14px;
    a {
      color: #222222;
    }
  }
  table {
    margin: 0;
  }
}

.card-header {

  background: #efefef;
  font-size: 18px;
  font-weight: 500;
}

.card {
  border: none;
  border-radius: 0.25rem;
}

.card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  .page-link {
    background-color: #982805;
    color: #FFF !important;

  }
}

/*-----------------------  Flash News -------------------------*/
.flash-news {
  background: #f3f3f3;
  margin-top: 2px;
  border-top: 1px solid #982805;
  border-bottom: 1px solid #982805;
  .flash-news-inner {
    display: flex;
  }
  .news-title {
    width: 113px;
    color: #FFF;
    background: #982805;
    padding: 5px 10px;
    font-weight: 700;
    position: relative;
    font-size: 14px;
    margin: 3px;
  }
  .news-marquee {
    width: calc(100% - 120px);
    color: #FFF;
    overflow: hidden;

  }
  .js-marquee {
    margin-top: 4px;
    font-size: 14px;
  }

}

.flash-news .news-marquee ul {
  padding: 0;
  margin: 0;
}

.flash-news .news-marquee ul li {
  float: left;
  margin-right: 30px;
  list-style-type: none;

  a {
    font-size: 14px;
    color: #982805 !important;
  }

  img {
    width: 60px;
    height: auto;
  }
}

.horti_notice {

  .section-title {
    text-align: left;
    margin-bottom: 15px;
  }
  ul {
    padding: 20px;
    margin: 0;
    border: 1px solid #e6e6e6;
    li {
      border-bottom: 1px solid #e6e6e6;
      padding: 5px 0;
      list-style-type: none;
      a {
        color: #982805;
      }
    }
  }

  .notice-list {
    .box {
      background: #FFF;
      border: 1px solid #e4e4e4;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0px 0px 17px -13px rgba(0, 0, 0, 0.75);
    }
  }

}

.gallery-slider {
  padding: 10px 0;
  .item {
    border-radius: 5px;
    overflow: hidden;
  }


}


.marquee-vert{
  overflow: hidden;
}