/*classic header*/

.nds_top_bar {
  background: #982805;
  padding: 5px 0;
  ul.social_links {
    padding: 0;
    margin: 0;
    @media (max-width: 992px) {
      text-align: center;
    }
    li {
      list-style-type: none;
      display: inline-block;
      a {
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        display: inline-block;
        transition: background 0.5s;
        color: #FFF;
        letter-spacing: 2px;
      }

      &.facebook a:hover, &.facebook a:focus {
        background: #3E5993;
      }

      &.twitter a:hover, &.twitter a:focus {
        background: #00C3F8;
      }

      &.youtube a:hover, &.youtube a:focus {
        background: #CC181E;
      }
    }
  }
  ul.contact-detail {
    padding: 0;
    margin: 0;
    text-align: right;
    @media (max-width: 992px) {
      text-align: center;
    }
    li {
      list-style-type: none;
      display: inline-block;
      margin-left: 7px;
      a {
        color: #fff;
        font-size: 13px;
        display: inline-block;
        line-height: 32px;
        letter-spacing: 2px;
      }

    }
  }
}


/*main header*/
.nds_main_header {
  padding: 0;
  position: relative;
  .flag {
     text-align: right;
    padding: 5px 0;
  }
  .logo{
    padding: 5px 0;
  }
}

.main-navigation {
  vertical-align: middle;
  width: 100%;
  position: relative;
}


.main-navigation ul.nav-menu>li:last-child>a{
  border-right: none;
}