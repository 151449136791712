/********************
*   Footer
*********************/

footer {


  &.site-footer {
    padding-top: 60px;
    color: $color_white;
    background: #023c6f;

    .widget {
      margin: 0;
      ul {
        padding: 0;
        margin: 0;
        li {
          font-size: 14px;
          list-style-type: none;
          display: block;
          font-family: $font_text;
          text-align: left;
          margin: 0;
          line-height: 30px;
          color: #bcbcbc;
          a {
            font-size: 14px;
            font-family: $font_text;
            line-height: 21px;
            color: #bcbcbc;
            display: block;
            margin-bottom: 15px;
            transition: all 0.5s ease;
            text-transform: initial;
            font-weight: 400;
            letter-spacing: 0;

            &:hover {
              color: $secondary_color;
            }
          }
        }
      }
    }

    .widget_nav_menu ul li {
      padding: 0px 7px 0px 10px;
      border: none;

      &:before {
        content: "-";
        color: #878787;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .widget .widget-title {
      font-size: 20px;
      color: $color_white;
      margin-bottom: 15px;
      font-weight: 700;
    }

  }
  .footer-menu {
    ul{
      li{
        display: inline-block !important;
        width: 48%;
      }
    }
  }
}

.copyright {
  p {
    font-size: 14px;
    font-family: $font_text;
    font-weight: 400;
    color: #fdfdfd;
    line-height: 1.7;
  }
  a {
    color: #e4e4e4;
  }
}

.bottom-footer {
  border-top: 1px solid #1a1a1a;
  background: #982805;
  padding: 10px;
  margin-top: 30px;
  p {
    margin: 0;
    @media (max-width: 736px) {
      text-align: center;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: right;
    @media (max-width: 736px) {
      text-align: center;
    }
    li {
      display: inline-block;
      margin-left: 20px;
      font-size: 14px;
      a {
        color: $color_white;
        &:hover {
          color: $secondary_color;
        }
      }

    }

  }
}
